<template>
  <template v-if="active.length">
    <TransitionGroup
      v-show="active.length"
      name="stack"
      tag="ul"
      class="notifications"
    >
      <li
        v-for="(notification, idx) in active"
        v-show="!isDismissed(notification)"
        class="item"
        :key="notification.uuid"
        :data-index="idx"
        :data-state="isDismissed(notification) ? 'pending' : 'active'"
      >
        <div
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :delay="idx * 100"
        >
          <NotificationStackItem
            @dismiss="dismiss(notification)"
            @confirm="confirm(notification)"
            @cancel="cancel(notification)"
          >
            <NotificationCard
              :data-uuid="notification.uuid"
              :label="notification.label"
              :body="notification.body"
              :read-more="notification.url"
              :is-expanded="idx === 0"
            />
          </NotificationStackItem>
        </div>
      </li>
    </TransitionGroup>
  </template>
</template>

<script lang="ts" setup>
import { useArrayDifference } from '@vueuse/core'
import type { UnwrapRef } from 'vue'

const { locale } = useI18n()

const {
  data: notifications,
  markSeen,
  markDismissed,
} = useNotifications({ locale })
type NotificationData = UnwrapRef<typeof notifications>[number]

const dismissed = ref<NotificationData[]>([])
const active = useArrayDifference(notifications, dismissed)

const isDismissed = (notification: NotificationData): boolean => {
  return dismissed.value.includes(notification)
}

const dismiss = (notification: NotificationData) => {
  dismissed.value.push(notification)
  markSeen(notification.uuid)
}

const cancel = (notification: NotificationData) => {
  dismissed.value = dismissed.value.filter((n) => n !== notification)
}

const confirm = (notification: NotificationData) => {
  markDismissed(notification.uuid)
}
</script>

<style lang="postcss">
.stack-enter-active,
.stack-leave-active {
  transition: all 0.5s ease !important;
}
.stack-enter-from,
.stack-leave-to {
  opacity: 0 !important;
  transform: translateX(30px) !important;
}
</style>

<style lang="postcss" scoped>
.notifications {
  @apply isolate grid w-full place-content-stretch;

  transform: translateY(1rem);
}

.item {
  @apply w-full list-none overflow-clip opacity-80 transition-all duration-100 ease-in-out;

  grid-column-start: 1;
  grid-row-start: 1;

  transform: translate(0px, -1rem) scale(0.96);
  transform-origin: top center;

  &:nth-child(1) {
    @apply h-fit min-h-[50px] rounded-lg opacity-100 shadow-sm shadow-brand-midnight;

    transform: translate(0px, 0px) scale(1);
    z-index: 2;
  }

  &:nth-child(2) {
    @apply opacity-90;

    transform: translate(0px, -0.5rem) scale(0.98);
    z-index: 1;
  }

  &:nth-child(-n + 3) {
    @apply visible;
  }

  &:not(:nth-child(-n + 1)) {
    @apply max-h-[100px];
  }

  &:not(:nth-child(-n + 3)) {
    @apply invisible;
  }
}
</style>

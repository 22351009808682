<template>
  <div
    class="group w-full rounded-lg border border-white bg-brand-sky p-4 dark:bg-brand-midnight"
    :class="`${isExpanded ? 'is-expanded' : 'is-collapsed'}`"
  >
    <div class="space-y-2">
      <header v-if="label">
        <h5
          class="title max-w-[25ch] text-sm font-semibold line-clamp-1 text-brand-turquoise dark:text-brand-sky"
        >
          {{ label }}
        </h5>
      </header>
      <div
        class="flex flex-col place-content-start font-medium text-brand-midnight dark:text-brand-sky"
      >
        <p class="message group-[.is-collapsed]:line-clamp-1">
          {{ body }}
        </p>
        <NuxtLink
          v-if="readMore"
          class="btn-ghost btn-sm btn self-end text-brand-turquoise dark:text-brand-sky"
          :to="readMore"
        >
          {{ t('Read more') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Url = string

const { t } = useI18n()

const props = defineProps<{
  label?: string
  body: string
  readMore?: Url
  isExpanded?: boolean
}>()

const isExpanded = computed(() => props.isExpanded ?? false)
</script>
